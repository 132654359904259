import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card, CardBody, CardHeader, Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap'
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import Config from '../../../helpers/Config';
import rootAction from '../../../stateManagment/actions/rootAction';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import Seo from '../../components/shared/Seo';
import LoadingScreen from '../../components/shared/LoadingScreen';


const ChangePassword = () => {
    const dispatch = useDispatch();
    const loginUser = useSelector(state => state.userReducer.user);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [OldPassword, setOldPassword] = useState('');
    const [NewPassword, setNewPassword] = useState('');
    const [CnfNewPassword, setCnfNewPassword] = useState('');
    const [currentPass, setcurrentPassToggle] = useState(true);
    const [newPass, setnewPassToggle] = useState(true);
    const [confirmPass, setconfirmPassToggle] = useState(true);
    const [isContentLoading,setIsContentLoading] = useState(false);

    const [ feedbackErrors, setFeedbackErrors ] = useState({});
    const [feedback, setFeedback] = useState({
        message: '',
        type: '',
        open: false
    });

    const validateForm = () => {
        const errors = {}

        // Password Validation
        const regEx_Uppercase = new RegExp(/.*[A-Z]/);
        const regEx_Number = new RegExp(/.*\d/);
        const regEx_Length = new RegExp(/.{8,}$/);
        const regEx_SpecialChars = new RegExp(
        /.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/
        );

        const regEx_PasswordValid = new RegExp(
        `^(?=${[
            regEx_Length.source,
            regEx_Uppercase.source,
            regEx_Number.source,
            regEx_SpecialChars.source
        ].join(")(?=")}).*$`
        );
        
        // Old Password
        if ( !OldPassword || OldPassword === '' ) errors.OldPassword = 'This field is required.'
        // New Password
        if ( !NewPassword || NewPassword === '' ) errors.NewPassword = 'This field is required.'
        else if ( NewPassword === OldPassword ) errors.NewPassword = 'New password shall be different than your current password.'
        else if ( regEx_PasswordValid.test(NewPassword) === false ) errors.NewPassword = 'Sorry, the provided password does not match the required constraints.'
        // Confirm Password
        if ( !CnfNewPassword || CnfNewPassword === '' ) errors.CnfNewPassword = 'This field is required.'
        else if ( CnfNewPassword !== NewPassword ) errors.CnfNewPassword = 'Sorry, the new password does not match.'

        return errors
    }

    const removeFeedbackError = (e) => {
        delete feedbackErrors[e];
        setFeedbackErrors({...feedbackErrors});
    }

    const handleUpdatePasswordForm = async (event) => {
        event.preventDefault();

        const feedbackErrors = validateForm();

        if ( Object.keys(feedbackErrors).length > 0 ) {
            // Invalid fields
            setFeedbackErrors(feedbackErrors)
        } else {
            // Valid fields
            try {

                setIsContentLoading(true);

                const headers = {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
    
    
                const param = {
                    requestParameters: {
                        Email: loginUser.EmailAddress,
                        OldPassword: OldPassword,
                        NewPassword: NewPassword
                    },
                };
    
                const passChangeResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['CHANGE_PASSWORD'], null, param, headers, "POST", true);
                if (passChangeResponse != null && passChangeResponse.data != null && passChangeResponse.data.errorMessage.length == 0) {
                    //setup the cart here
                    let finalData = JSON.parse(passChangeResponse.data.data);
    
                    if (finalData.disposition) {
                        dispatch(rootAction.userAction.setUserToken(finalData.jwtToken));
                        setFeedback({
                            type: 'success',
                            message: 'Password changed successfully',
                            open: true
                        });
                    }
                    else {
                        if (finalData.fault.message.includes("The update password request is invalid")) {
                            setFeedback({
                                type: 'danger',
                                message: 'Error while changing password. Ensure that your old password is correct and that your new password is not the same as your old password and is at least 8 characters in length and includes Uppercase, Lowercase, Number and Special Character.',
                                open: true
                            });
                        } else {
                            setFeedback({
                                type: 'danger',
                                message: 'Error while changing password. ' + finalData.fault.message,
                                open: true
                            });
                        }
                    }
    
                }
    
            } catch (err) {
                setFeedback({
                    type: 'danger',
                    message: 'An error occured. Please try again!',
                    open: true
                });
    
                return false;
    
            } finally {
                //--stop loader
                dispatch(rootAction.commonAction.stopLoading());
                setIsContentLoading(false);
                setOldPassword('');
                setNewPassword('');
                setCnfNewPassword('');
                window.scrollTo({top: 0, behavior: 'smooth'});
            }
        }
    }

    useEffect(() => {
        // declare the data fetching function
        const dataOperationInUseEffect = async () => {

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["UpdateProfile"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }

        }

        //--start loader
        dispatch(rootAction.commonAction.setLoading(true));

        // call the function
        dataOperationInUseEffect().catch(console.error);

        //--stop loader
        dispatch(rootAction.commonAction.stopLoading());

    }, [])

    return (
        <>
            <Seo title="Change Password" description="Change Password" keywords="Change Password" />
            <LoadingScreen loading={isContentLoading} />
            <SiteBreadcrumb title={"<a href='/" + getLanguageCodeFromSession() + "/my-account/'>My Account</a>" + "<span class='sep'>/</span>Change Password"} />
            
            <section className="edit-content-page pb-8 pb-md-9" id="edit-password">
                <div className="container-fluid ">
                    <div className="section-title d-flex align-items-center justify-content-between mt-md-8 mb-5">
                        <h1 className="h2 mb-0">
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Password", "lbl_myaccount_password")
                                :
                                "Password"
                            }
                        </h1>
                        <Link to={`/${getLanguageCodeFromSession()}/my-account`} className="btn-link link-underline">
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Back to My Account", "lbl_myaccount_backtomyaccount")
                                :
                                "Back to My Account"
                            }
                        </Link>
                    </div>
                    
                    <Alert color={feedback.type} isOpen={feedback.open}>
                        {feedback.message}
                    </Alert>

                    <div className="row">
                        <div className="col-12">
                            <Card color='light' className='card-my-account border-0'>
                                <CardHeader tag='h5' className='no-bg-header border-bottom-0 pt-md-6'>
                                    {LocalizationLabelsArray.length > 0 ?
                                        replaceLoclizationLabel(LocalizationLabelsArray, "Edit Password", "lbl_myaccount_editprofile")
                                        :
                                        "Edit Password"
                                    }
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleUpdatePasswordForm} noValidate>
                                        <FormGroup className='required position-relative'>
                                            <Label>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Current Password", "lbl_check_currentpassword")
                                                    :
                                                    "Current Password"
                                                }
                                            </Label>
                                            <span className="btn-show-password btn-link position-absolute" role="button" tabIndex="0" onClick={(e) => {e.target.blur(); setcurrentPassToggle(!currentPass)}}>
                                                {currentPass ?
                                                <>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Show", "lbl_check_show")
                                                        :
                                                        "Show"
                                                    }
                                                </>
                                                :
                                                <>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Hide", "lbl_check_hide")
                                                        :
                                                        "Hide"
                                                    }
                                                </>
                                                }
                                            </span>
                                            {currentPass ?
                                                <Input type='password' name='OldPassword' id='OldPassword' value={OldPassword}
                                                    onChange={(event) => setOldPassword(event.target.value)}
                                                    onFocus={(event) => removeFeedbackError(event.target.id)}
                                                    invalid={!!feedbackErrors.OldPassword}
                                                >
                                                </Input>
                                            :
                                                <Input type='text' name='OldPassword' id='OldPassword' value={OldPassword}
                                                    onChange={(event) => setOldPassword(event.target.value)}
                                                    onFocus={(event) => removeFeedbackError(event.target.id)}
                                                    invalid={!!feedbackErrors.OldPassword}
                                                >
                                                </Input>
                                            }
                                            <FormFeedback type='invalid'>{feedbackErrors.OldPassword}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required position-relative'>
                                            <Label>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "New Password", "lbl_check_newpassword")
                                                    :
                                                    "New Password"
                                                }
                                            </Label>
                                            <span className="btn-show-password btn-link position-absolute" role="button" tabIndex="0" onClick={(e) => {e.target.blur(); setnewPassToggle(!newPass)}}>
                                                {newPass ?
                                                <>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Show", "lbl_check_show")
                                                        :
                                                        "Show"
                                                    }
                                                </>
                                                :
                                                <>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Hide", "lbl_check_hide")
                                                        :
                                                        "Hide"
                                                    }
                                                </>
                                                }
                                            </span>
                                            {newPass ?
                                                <Input type='password' name='NewPassword' id='NewPassword' minLength='8' value={NewPassword}
                                                    onChange={(event) => setNewPassword(event.target.value)}
                                                    onFocus={(event) => {removeFeedbackError(event.target.id); removeFeedbackError('CnfNewPassword')}}
                                                    invalid={!!feedbackErrors.NewPassword}
                                                >
                                                </Input>
                                            :
                                                <Input type='text' name='NewPassword' id='NewPassword' minLength='8' value={NewPassword}
                                                    onChange={(event) => setNewPassword(event.target.value)}
                                                    onFocus={(event) => {removeFeedbackError(event.target.id); removeFeedbackError('CnfNewPassword')}}
                                                    invalid={!!feedbackErrors.NewPassword}
                                                >
                                                </Input>
                                            }
                                            <small id="password-help" className="form-text">Password must be at least 8 characters and include Uppercase, Lowercase, Number and Special Character.</small>
                                            <FormFeedback type='invalid'>{feedbackErrors.NewPassword}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required position-relative'>
                                            <Label>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Confirm New Password", "lbl_check_confirmnewpassword")
                                                    :
                                                    "Confirm New Password"
                                                }
                                            </Label>
                                            <span className="btn-show-password btn-link position-absolute" role="button" tabIndex="0" onClick={(e) => {e.target.blur(); setconfirmPassToggle(!confirmPass)}}>
                                                {confirmPass ?
                                                <>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Show", "lbl_check_show")
                                                        :
                                                        "Show"
                                                    }
                                                </>
                                                :
                                                <>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Hide", "lbl_check_hide")
                                                        :
                                                        "Hide"
                                                    }
                                                </>
                                                }
                                            </span>
                                            <Input type={confirmPass ? 'password' : 'text' } name='CnfNewPassword' id='CnfNewPassword' minLength='8' value={CnfNewPassword}
                                                onChange={(event) => setCnfNewPassword(event.target.value)}
                                                onFocus={(event) => removeFeedbackError(event.target.id)}
                                                invalid={!!feedbackErrors.CnfNewPassword}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.CnfNewPassword}</FormFeedback>
                                        </FormGroup>

                                        <div className='row'>
                                            <div className="col-12 col-md mb-4 mb-md-0">
                                                <button type="submit" name="save" className="btn btn-save btn-block btn-primary">
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Save", "lbl_myaccount_save")
                                                        :
                                                        "Save"
                                                    }
                                                </button>
                                            </div>
                                            <div className="col-12 col-md">
                                                <Link to={`/${getLanguageCodeFromSession()}/my-account`} className="btn btn-outline-primary bg-white w-100">
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Cancel", "lbl_myaccount_cancel")
                                                        :
                                                        "Cancel"
                                                    }
                                                </Link>
                                            </div>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ChangePassword;
