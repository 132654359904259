import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../stateManagment/reduxStore";
import { useSelector } from "react-redux";
import { useLazyValidateAddressQuery } from "../../../services/user";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import LoadingWrapper from "../../components/products/LoadingWrapper";
import { AddressSuggestModal } from "./AddressSuggestModal";
import { validateZipCode } from '../../../helpers/ValidationHelper';
import rootAction from "../../../stateManagment/actions/rootAction";
import { replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import { useLazyGetSmartySuggestionsQuery } from "../../../services/site";
import debounce from 'lodash.debounce';
import Config from '../../../helpers/Config';
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';

export const AddressEditModal = ({ isOpen, toggle, type, hasCreditCardPaymentMethod, isAddNewAllotmentShippingAddress}) => {
    const dispatch = useAppDispatch();
    const [formAddress, setFormAddress] = useState({});
    const [selectedAddress, setSelectedAddress] = useState(0);
    const { shippingAddress, billingAddress, shippingOnlyFromDropdown, canAddNewBillingAddress } = useSelector((state) => state.checkout);
    const { smartyStreets: { acSettings: { c_enableSmartyStreetsAutoComplete: ssACEnabled }, avsSettings: { c_enableSmartyStreetsAddressValidation: ssAVSEnabled } } } = useSelector((state) => state.commonReducer);
    const [smartyValidate, { isFetching: isFetchingAddress }] = useLazyValidateAddressQuery();
    const [openSuggestModal, setOpenSuggestModal] = useState(false);
    const [openSuggestDropdown, setOpenSuggestDropdown] = useState(false);
    const [addressIsValid, setAddressIsValid] = useState(false);
    const [isAddressFromSuggestions, setIsAddressFromSuggestions] = useState(false);
    const [ignoreFormHandling, setIgnoreFormHandling] = useState(false);
    const [smartySuggestion, setSmartySuggestion] = useState(null);
    const [smartySuggestionDropdown, setSmartySuggestionDropdown] = useState([]);
    const LocalizationLabelsArray = [];
    const [modalTitle, setModalTitle] = useState("Edit " + type.charAt(0).toUpperCase() + type.slice(1) + " Address");
    const closeBtn = <button className="close" onClick={toggle}><span className="icon icon-close"></span></button>;
    const [smartySuggest, { isFetching: isFetchingSuggestions }] = useLazyGetSmartySuggestionsQuery();
    const [feedbackErrors, setFeedbackErrors] = useState({});
    const { shipToLocations, shipToLocationEnabled, hasSelectPaymentOptions, selectedPaymentMethod, isDedicatedAllotmentAddressesEnabled, isAddAllotmentAddressEnabled, allotmentAddresses } = useSelector((state) => state.checkout);
    const [isAllotmentMappedLocationAddress, setIsAllotmentMappedLocationAddress] = useState(false);
    const [isAddressModified, setIsAddressModified] = useState(false);
    const { user: { EmployeeID: EmployeeID }}  = useSelector((state) => state.userReducer);
    const checkoutActivity = useSelector((state) => state.checkout);
    const removeFormError = (e) => {
        delete feedbackErrors[e];
        setFeedbackErrors({ ...feedbackErrors });
    }
    const validateFields = () => {
        // Clean previous errors first
        const validateKeys = ['firstName', 'lastName', 'address1', 'city', 'stateCode', 'countryCode', 'postalCode', 'phone', 'allotmentAddressLabel'];
        validateKeys.forEach(key => {
            delete feedbackErrors[key];
        });
        // Collect errors
        validateKeys.forEach(key => {
            if (key === 'firstName' || key === 'lastName') {
                if (formAddress[key]?.length === 0) {
                    feedbackErrors[key] = 'Please fill out this field.';
                } else if (formAddress[key]?.match(/^[a-zA-Z-]*$/) === null) {
                    feedbackErrors[key] = 'Please check the spelling, invalid characters detected'
                }
            } else if (key === 'phone') {
                if (formAddress[key]?.length === 0) {
                    feedbackErrors[key] = 'Please fill out this field.';
                } else if (formAddress[key]?.length != 10 || formAddress[key]?.match(/^\d+$/) === null) {
                    feedbackErrors[key] = 'Phone Number must be 10 characters long (numbers only).'
                }
            } else if (key === 'countryCode' || key === 'stateCode') {
                if (formAddress[key]?.length === 0) {
                    feedbackErrors[key] = 'Please select an option.'
                }
            } else if (key === 'postalCode') {
                if (formAddress[key]?.length === 0) {
                    feedbackErrors[key] = 'Please fill out this field.';
                }
                else if (!validateZipCode(formAddress[key])) {
                    feedbackErrors[key] = 'Please enter a valid zip code.';
                }
            } else if (key == 'allotmentAddressLabel') {
                if (isDedicatedAllotmentAddressesEnabled && formAddress[key]?.length === 0) {
                    feedbackErrors[key] = 'Please fill out this field.';
                }
            } else {
                setFormAddress({
                    ...formAddress,
                    key: formAddress[key]?.trim()
                });
                if (formAddress[key].trim().length === 0) {
                    feedbackErrors[key] = 'Please fill out this field.'
                }
            }
        });
        setFeedbackErrors({ ...feedbackErrors });
    };

    useEffect(() => {
        if (type === 'billing' && billingAddress) {
            setFormAddress(billingAddress);
        } else if (type === 'shipping' && shippingAddress) {
            setFormAddress(shippingAddress);
            setIsAddressModified(false);
            if (isDedicatedAllotmentAddressesEnabled) {
                if (isAddAllotmentAddressEnabled && isAddNewAllotmentShippingAddress) {
                    setModalTitle('Add Shipping Address');
                    setFormAddress({
                        ...shippingAddress,
                        id: 'shipping',
                        c_addressType: 'S',
                        firstName: '',
                        lastName: '',
                        address1: '',
                        address2: '',
                        city: '',
                        stateCode: '',
                        postalCode: '',
                        phone: '',
                        allotmentAddressLabel: '',
                        allotmentAddressId: 0,
                        allotmentIsDefault: false,
                        c_isSmartyValidated: false, 
                        email: '',
                        fullName: ''
                    });
                    setIsAllotmentMappedLocationAddress(false);
                } else {
                    //Do not allow edit on Allotment mapped location addresses
                    setIsAllotmentMappedLocationAddress(shippingAddress.c_addressType === 'M');
                }
            }
        }
        return () => {
            setFormAddress({});
            setSmartySuggestion(null);
        }
    }, [shippingAddress, billingAddress]);

    useEffect(() => {
        if (shipToLocations.length > 0 && type === 'shipping' && shippingAddress) {
            const findShipToLocation = shipToLocations.find(addr => addr.id === shippingAddress.id);
            setSelectedAddress(findShipToLocation?.id || 0);
        }
    }, [shipToLocations, shippingAddress]);

    useEffect(() => {
        if (allotmentAddresses?.length > 0 && type === 'shipping' && shippingAddress) {
            const selectedAllotmentAddress = allotmentAddresses.find(addr => addr.allotmentAddressId === shippingAddress.allotmentAddressId);
            setSelectedAddress(selectedAllotmentAddress?.allotmentAddressId || 0);
        }
    }, [allotmentAddresses, shippingAddress]);

    const saveAllotmentAddress = async (event) => {
        try {
            dispatch(rootAction.commonAction.setLoading(true));
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };
            var params = {
                requestParameters: {
                    employeeId: EmployeeID,
                    firstName: formAddress.firstName,
                    lastName: formAddress.lastName,
                    address1: formAddress.address1,
                    address2: formAddress.address2,
                    city: formAddress.city,
                    stateCode: formAddress.stateCode,
                    countryCode: formAddress.countryCode,
                    postalCode: formAddress.postalCode,
                    phone: formAddress.phone,
                    allotmentAddressLabel: formAddress.allotmentAddressLabel,
                    allotmentAddressId: formAddress.allotmentAddressId
                }
            };
            var response = await MakeApiCallAsync(Config.END_POINT_NAMES['SAVE_ALLOTMENT_SHIPPING_ADDRESS'], null, params, headers, "POST", true);
            dispatch(rootAction.commonAction.setLoading(false));
            let parsed = JSON.parse(response.data.data);
            if (parsed) {
                let fullName = shipToLocationEnabled ? formAddress.fullName : (formAddress.firstName + ' ' + formAddress.lastName).trim();
                var newAllotmentAddress = {
                    ...formAddress,
                    allotmentAddressId: parsed.allotmentAddressId,
                    fullName
                };
                if (isAddNewAllotmentShippingAddress) {
                    dispatch(rootAction.checkoutActions.setAddressActivity({
                        ...checkoutActivity,
                        allotmentAddresses: [...allotmentAddresses, newAllotmentAddress]
                    }));
                }
                dispatch(rootAction.checkoutActions.setShippingAddress(newAllotmentAddress));
                toggle();
            } else {
                console.log(parsed.Message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (addressIsValid) {
            if (type === 'billing') {
                const fullName = formAddress.firstName === undefined && formAddress.fullName ? formAddress.fullName : (formAddress.firstName + ' ' + formAddress.lastName).trim();
                dispatch(rootAction.checkoutActions.setBillingAddress({
                    ...formAddress,
                    fullName
                }));
                toggle();
            } else {
                //Save allotment address
                if (isDedicatedAllotmentAddressesEnabled && !isAllotmentMappedLocationAddress && isAddressModified) {
                    saveAllotmentAddress();
                } else {
                    let fullName = shipToLocationEnabled ? formAddress.fullName : (formAddress.firstName + ' ' + formAddress.lastName).trim();
                    if (isAllotmentMappedLocationAddress) {
                        fullName = '';
                    }
                    var selectedAddress = {
                        ...formAddress,
                        fullName: fullName
                    };
                    if (hasSelectPaymentOptions && selectedPaymentMethod !== 'ON_ACCOUNT') {
                        dispatch(rootAction.checkoutActions.setEdittedAddress(selectedAddress));
                    }
                    dispatch(rootAction.checkoutActions.setShippingAddress(selectedAddress));
                    toggle();
                }
            }
        }
        return () => {
            setFormAddress({});
            setAddressIsValid(false);
        }
    }, [addressIsValid]);

    const verifyAddress = async (e) => {
        e.preventDefault();
        if(ignoreFormHandling === null) {
            setAddressIsValid(true);
            return;
        }
        
        if (shipToLocationEnabled && type === 'shipping') {
            setAddressIsValid(true);
            return;
        }

        //Do not validate allotment mapped location addresses
        if (isDedicatedAllotmentAddressesEnabled && isAllotmentMappedLocationAddress) {
            setAddressIsValid(true);
            return;
        }

        validateFields();
        if (Object.keys(feedbackErrors).length > 0) {
            return;
        }
        if (!ssAVSEnabled || isAddressFromSuggestions) {
            return setAddressIsValid(true);
        }
        const addressValidation = await smartyValidate({
            ...formAddress
        }).unwrap();
        if (addressValidation?.response?.length > 0) {
            const firstSuggestion = addressValidation.response[0];
            const addressBits = firstSuggestion.last_line.split(' ');
            setSmartySuggestion({
                ...formAddress,
                address1: firstSuggestion.delivery_line_1,
                city: addressBits[0],
                stateCode: addressBits[1],
                postalCode: addressBits[2]
            });
            setOpenSuggestModal(true);
        } else {
            setAddressIsValid(true);
        }
    };

    const toggleSuggestModal = () => {
        setOpenSuggestModal(!openSuggestModal);
    }

    const toggleSuggestDropdown = () => {
        setOpenSuggestDropdown(ssAVSEnabled && !openSuggestDropdown);
    }

    const handleAddressChange = (e) => {
        if (!ignoreFormHandling) {
            setIsAddressFromSuggestions(false);
        }
        setIsAddressModified(true);
        setFormAddress({
            ...formAddress,
            [e.target.name]: e.target.value
        });
    };

    const handleShipToChange = (e) => {
        const selectedAddress = shipToLocations.find(addr => addr.id === e.target.value);
        if (selectedAddress) {
            setSelectedAddress(selectedAddress.id);
            setFormAddress({ ...selectedAddress });
        }
    };
    const handleAllotmentAddressChange = (e) => {
        const selectedAddress = allotmentAddresses.find(addr => addr.allotmentAddressId == e.target.value);
        if (selectedAddress) {
            setSelectedAddress(selectedAddress.allotmentAddressId);
            setFormAddress({ ...selectedAddress });
            setIsAllotmentMappedLocationAddress(selectedAddress.c_addressType === 'M');
        }
    };
    const suggestAddressDropdown = (e) => {
        if (!ssACEnabled || e.target.value.length < 3) {
            return;
        }
        smartySuggest({ search: e.target.value }).unwrap().then((res) => {
            if (res.data?.response.length > 0) {
                setSmartySuggestionDropdown(res.data?.response);
            }
            setOpenSuggestDropdown(res.data?.response.length > 0);
        });
    }
    const debouncedSuggestAddressDropdown = useMemo(
        () => {
            return debounce(suggestAddressDropdown, 1000)
        }, []);

    const selectSmartyAddress = (item) => {
        setFormAddress({
            ...formAddress,
            address1: item.street_line,
            address2: '',
            city: item.city,
            stateCode: item.state,
            postalCode: item.zipcode
        });
        setSmartySuggestionDropdown([]);
        setIsAddressFromSuggestions(true);
        setIgnoreFormHandling(true);
    }

    const getShipToString = (addr) => {
        return [
            addr.fullName,
            addr.address1,
            addr.address2,
            addr.city + ', ' + addr.stateCode,
            addr.postalCode
        ].filter((o) => { return o != undefined }).join(' ');
    };

    const getAllotmentAddressToString = (addr) => {
        return [
            addr.allotmentAddressLabel ? addr.allotmentAddressLabel + ', ' + addr.address1 : addr.address1,
            addr.address2,
            addr.city + ', ' + addr.stateCode,
            addr.postalCode
        ].filter((o) => { return o != undefined }).join(' ');
    };

    return (
        <>
            {openSuggestModal && smartySuggestion?.address1 &&
                <AddressSuggestModal isOpen={openSuggestModal} toggle={toggleSuggestModal} address={formAddress} setAddress={setFormAddress} setAddressIsValid={setAddressIsValid} smartySuggestion={smartySuggestion} />}

            <Modal centered={true} isOpen={isOpen} toggle={toggle} className='address-edit-modal'>
                <LoadingWrapper isLoading={isFetchingAddress}>
                    <ModalHeader toggle={toggle} close={closeBtn}>
                        {LocalizationLabelsArray.length > 0 ?
                            replaceLoclizationLabel(LocalizationLabelsArray, modalTitle, "lbl_check_edit" + type + "address")
                            :
                            modalTitle
                        }
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={verifyAddress} autoComplete="off" className={(type === 'shipping' && shippingOnlyFromDropdown) || (type === 'billing' && !canAddNewBillingAddress) ? 'd-none' : ''}>
                            <div className='row'>
                                {shipToLocationEnabled && type === 'shipping' ?
                                    <>
                                        <div className='col-sm-12'>
                                            <FormGroup className='required'>
                                                <Label for='selShipToLocation'>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Ship To Location", "lbl_ship_to_location")
                                                        :
                                                        "Ship To Location"
                                                    }
                                                </Label>
                                                <Input type='select' name='selShipToLocation' id='selShipToLocation'
                                                    value={selectedAddress || shipToLocations[0]?.id || ''}
                                                    onChange={handleShipToChange}
                                                >
                                                    {shipToLocations.map(addr => {
                                                        return <option key={addr.id} id={addr.id} value={addr.id}>{getShipToString(addr)}</option>
                                                    })}
                                                </Input>
                                                <FormFeedback type='invalid'>{feedbackErrors.firstName}</FormFeedback>
                                            </FormGroup>
                                        </div>

                                        <div className='col-sm-12'>
                                            <FormGroup className='required'>
                                                <Label for='fullName'>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Full Name", "lbl_check_city")
                                                        :
                                                        "Full Name"
                                                    }
                                                </Label>
                                                <Input type='text' name='fullName' id='fullName' placeholder='Full Name' maxLength='100'
                                                    value={formAddress.fullName || ''}
                                                    disabled={true}
                                                />
                                                <FormFeedback type='invalid'>{feedbackErrors.firstName}</FormFeedback>
                                            </FormGroup>
                                        </div>
                                    </>
                                    :
                                    <>
                                        { isDedicatedAllotmentAddressesEnabled && type === 'shipping' && !isAddNewAllotmentShippingAddress && allotmentAddresses?.length > 1  ?
                                            <>
                                                <div className='col-sm-12'>
                                                    <FormGroup className='required'>
                                                        <Label for='selAllotmentAddress'>
                                                            {LocalizationLabelsArray.length > 0 ?
                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Ship To Location", "lbl_ship_to_location")
                                                                :
                                                                "Ship To Location"
                                                            }
                                                        </Label>
                                                        <Input type='select' name='selAllotmentAddress' id='selAllotmentAddress'
                                                            value={selectedAddress}
                                                            onChange={handleAllotmentAddressChange}
                                                        >
                                                            {allotmentAddresses.map(addr => {
                                                                return <option key={addr.allotmentAddressId} id={addr.allotmentAddressId} value={addr.allotmentAddressId}>{getAllotmentAddressToString(addr)}</option>
                                                            })}
                                                        </Input>
                                                        <FormFeedback type='invalid'>{feedbackErrors.firstName}</FormFeedback>
                                                    </FormGroup>
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                        }
                                        { isDedicatedAllotmentAddressesEnabled && type === 'shipping' ?
                                            <>
                                                <div className='col-12'>
                                                    <FormGroup className='required'>
                                                        <Label for='allotmentAddressLabel'>
                                                            {LocalizationLabelsArray.length > 0 ?
                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Address Label", "lbl_check_addresslabel")
                                                                :
                                                                "Address Label"
                                                            }
                                                        </Label>
                                                        <Input type='text' name='allotmentAddressLabel' id='allotmentAddressLabel' placeholder='Address Label' maxLength='50'
                                                            value={formAddress.allotmentAddressLabel || ''}
                                                            onChange={handleAddressChange}
                                                            disabled={isAllotmentMappedLocationAddress}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                </>
                                            :
                                            <>
                                            </>
                                        }
                                        { formAddress.c_addressType !== 'M' ?
                                            <>
                                                <div className='col-sm-6'>
                                                    <FormGroup className='required'>
                                                        <Label for='firstName'>
                                                            {LocalizationLabelsArray.length > 0 ?
                                                                replaceLoclizationLabel(LocalizationLabelsArray, "First Name", "lbl_check_city")
                                                                :
                                                                "First Name"
                                                            }
                                                        </Label>
                                                        <Input type='text' name='firstName' id='firstName' placeholder='First Name' maxLength='15'
                                                            value={formAddress.firstName || ''}
                                                            onChange={handleAddressChange}
                                                            onFocus={(e) => removeFormError(e.target.id)}
                                                            invalid={!!feedbackErrors.firstName}
                                                        />
                                                        <FormFeedback type='invalid'>{feedbackErrors.firstName}</FormFeedback>
                                                    </FormGroup>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <FormGroup className='required'>
                                                        <Label for='firstName'>
                                                            {LocalizationLabelsArray.length > 0 ?
                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Last Name", "lbl_check_city")
                                                                :
                                                                "Last Name"
                                                            }
                                                        </Label>
                                                        <Input type='text' name='lastName' id='lastName' placeholder='Last Name' maxLength='20'
                                                            value={formAddress.lastName || ''}
                                                            onChange={handleAddressChange}
                                                            onFocus={(e) => removeFormError(e.target.id)}
                                                            invalid={!!feedbackErrors.lastName}
                                                        />
                                                        <FormFeedback type='invalid'>{feedbackErrors.lastName}</FormFeedback>
                                                    </FormGroup>
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                        }
                                    </>
                                }
                                
                                <div className='col-12'>
                                    <FormGroup className='required'>
                                        <Label for='address1'>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "Address 1", "lbl_check_address1")
                                                :
                                                "Address 1"
                                            }
                                        </Label>
                                        <Dropdown className="position-relative" isOpen={openSuggestDropdown} toggle={toggleSuggestDropdown}>
                                            <DropdownToggle tag="div">
                                                <Input type='text' name='address1' id='address1' placeholder='Address' maxLength='35'
                                                    value={formAddress.address1 || ''}
                                                    onChange={(e) => { handleAddressChange(e); debouncedSuggestAddressDropdown(e); }}
                                                    onFocus={(e) => removeFormError(e.target.id)}
                                                    invalid={!!feedbackErrors.address1}
                                                    autoComplete={ssACEnabled ? "address1" : ""}
                                                    disabled={(!!shipToLocationEnabled && type === 'shipping') || isAllotmentMappedLocationAddress}
                                                />
                                                <FormFeedback type='invalid'>{feedbackErrors.address1}</FormFeedback>
                                            </DropdownToggle>
                                            {smartySuggestionDropdown.length > 0 && <DropdownMenu>
                                                {smartySuggestionDropdown?.map((item) => {
                                                    return (
                                                        <DropdownItem style={{ ':hover': { color: '#fff' } }} key={item.asString} onClick={() => { selectSmartyAddress(item) }}>{item.asString}</DropdownItem>
                                                    )
                                                })}
                                            </DropdownMenu>}
                                            {isFetchingSuggestions && <Spinner className="position-absolute" style={{ right: 10, top: 10, left: 'auto' }} />}
                                        </Dropdown>
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup>
                                        <Label for='address2'>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "Address 2 (optional)", "lbl_check_address2optional")
                                                :
                                                "Address 2 (optional)"
                                            }
                                        </Label>
                                        <Input type='text' name='address2' id='address2' placeholder='Address 2' maxLength='35'
                                            value={formAddress.address2 || ''}
                                            onChange={handleAddressChange}
                                            disabled={(shipToLocationEnabled && type === 'shipping') || (type === 'billing' && !hasCreditCardPaymentMethod) || isAllotmentMappedLocationAddress}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-sm-6'>
                                    <FormGroup className='required'>
                                        <Label for='city'>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "City", "lbl_check_city")
                                                :
                                                "City"
                                            }
                                        </Label>
                                        <Input type='text' name='city' id='city' placeholder='City' maxLength='30'
                                            value={formAddress.city || ''}
                                            onChange={handleAddressChange}
                                            onFocus={(e) => removeFormError(e.target.id)}
                                            invalid={!!feedbackErrors.city}
                                            autoComplete={ssACEnabled ? "city" : ""}
                                            disabled={(shipToLocationEnabled && type === 'shipping') || (type === 'billing' && !hasCreditCardPaymentMethod) || isAllotmentMappedLocationAddress}
                                        />
                                        <FormFeedback type='invalid'>{feedbackErrors.city}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className='col-sm-6'>
                                    <FormGroup className='required'>
                                        <Label for='stateCode'>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "State", "lbl_check_state")
                                                :
                                                "State"
                                            }
                                        </Label>
                                        <Input type='select' name='stateCode' id='stateCode' placeholder='State'
                                            value={formAddress.stateCode || ''}
                                            onChange={handleAddressChange}
                                            onFocus={(e) => removeFormError(e.target.id)}
                                            invalid={!!feedbackErrors.stateCode}
                                            disabled={(shipToLocationEnabled && type === 'shipping') || (type === 'billing' && !hasCreditCardPaymentMethod) || isAllotmentMappedLocationAddress}
                                        >
                                            <option id="" value="">Select State</option>
                                            <option id="AL" value="AL">Alabama</option>
                                            <option id="AK" value="AK">Alaska</option>
                                            <option id="AS" value="AS">American Samoa</option>
                                            <option id="AZ" value="AZ">Arizona</option>
                                            <option id="AR" value="AR">Arkansas</option>
                                            <option id="CA" value="CA">California</option>
                                            <option id="CO" value="CO">Colorado</option>
                                            <option id="CT" value="CT">Connecticut</option>
                                            <option id="DE" value="DE">Delaware</option>
                                            <option id="DC" value="DC">District of Columbia</option>
                                            <option id="FL" value="FL">Florida</option>
                                            <option id="GA" value="GA">Georgia</option>
                                            <option id="GU" value="GU">Guam</option>
                                            <option id="HI" value="HI">Hawaii</option>
                                            <option id="ID" value="ID">Idaho</option>
                                            <option id="IL" value="IL">Illinois</option>
                                            <option id="IN" value="IN">Indiana</option>
                                            <option id="IA" value="IA">Iowa</option>
                                            <option id="KS" value="KS">Kansas</option>
                                            <option id="KY" value="KY">Kentucky</option>
                                            <option id="LA" value="LA">Louisiana</option>
                                            <option id="ME" value="ME">Maine</option>
                                            <option id="MD" value="MD">Maryland</option>
                                            <option id="MA" value="MA">Massachusetts</option>
                                            <option id="MI" value="MI">Michigan</option>
                                            <option id="MN" value="MN">Minnesota</option>
                                            <option id="MS" value="MS">Mississippi</option>
                                            <option id="MO" value="MO">Missouri</option>
                                            <option id="MT" value="MT">Montana</option>
                                            <option id="NE" value="NE">Nebraska</option>
                                            <option id="NV" value="NV">Nevada</option>
                                            <option id="NH" value="NH">New Hampshire</option>
                                            <option id="NJ" value="NJ">New Jersey</option>
                                            <option id="NM" value="NM">New Mexico</option>
                                            <option id="NY" value="NY">New York</option>
                                            <option id="NC" value="NC">North Carolina</option>
                                            <option id="ND" value="ND">North Dakota</option>
                                            <option id="MP" value="MP">North Mariana Islands</option>
                                            <option id="OH" value="OH">Ohio</option>
                                            <option id="OK" value="OK">Oklahoma</option>
                                            <option id="OR" value="OR">Oregon</option>
                                            <option id="PA" value="PA">Pennsylvania</option>
                                            <option id="PR" value="PR">Puerto Rico</option>
                                            <option id="RI" value="RI">Rhode Island</option>
                                            <option id="SC" value="SC">South Carolina</option>
                                            <option id="SD" value="SD">South Dakota</option>
                                            <option id="TN" value="TN">Tennessee</option>
                                            <option id="TX" value="TX">Texas</option>
                                            <option id="UT" value="UT">Utah</option>
                                            <option id="VT" value="VT">Vermont</option>
                                            <option id="VI" value="VI">Virgin Islands</option>
                                            <option id="VA" value="VA">Virginia</option>
                                            <option id="WA" value="WA">Washington</option>
                                            <option id="WV" value="WV">West Virginia</option>
                                            <option id="WI" value="WI">Wisconsin</option>
                                            <option id="WY" value="WY">Wyoming</option>
                                            <option id="AA" value="AA">Armed Forces AA</option>
                                            <option id="AE" value="AE">Armed Forces AE</option>
                                            <option id="AP" value="AP">Armed Forces AP</option>
                                            <option className="d-none" id="OTHER" value="OTHER">OTHER</option>
                                        </Input>
                                        <FormFeedback type='invalid'>{feedbackErrors.stateCode}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className='col-sm-6'>
                                    <FormGroup className='required'>
                                        <Label for='postalCode'>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "Zip", "lbl_check_zip")
                                                :
                                                "Zip"
                                            }
                                        </Label>
                                        <Input type='text' name='postalCode' id='postalCode' placeholder='Zip' minLength='5' maxLength='10'
                                            value={formAddress.postalCode || ''}
                                            onChange={handleAddressChange}
                                            onFocus={(e) => removeFormError(e.target.id)}
                                            invalid={!!feedbackErrors.postalCode}
                                            autoComplete={ssACEnabled ? "postalCode" : ""}
                                            disabled={(shipToLocationEnabled && type === 'shipping') || (type === 'billing' && !hasCreditCardPaymentMethod) || isAllotmentMappedLocationAddress}
                                        />
                                        <FormFeedback type='invalid'>{feedbackErrors.postalCode}</FormFeedback>
                                    </FormGroup>
                                </div>
                                <div className='col-sm-6'>
                                    <FormGroup className='required'>
                                        <Label for='country'>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "Country", "lbl_check_country")
                                                :
                                                "Country"
                                            }
                                        </Label>
                                        <Input disabled={true} type='text' name='countryCode' id='country' placeholder='Country'
                                            value={formAddress.countryCode || ''}
                                            onChange={handleAddressChange}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-sm-6'>
                                    <FormGroup className='required mb-md-0'>
                                        <Label for='phone'>
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "Phone", "lbl_check_phone")
                                                :
                                                "Phone"
                                            }
                                        </Label>
                                        <Input type='text' name='phone' id='phone' placeholder='Phone' maxLength='10'
                                            value={formAddress.phone || ''}
                                            onChange={handleAddressChange}
                                            onFocus={(e) => removeFormError(e.target.id)}
                                            invalid={!!feedbackErrors.phone}
                                            disabled={(shipToLocationEnabled && type === 'shipping') || (type === 'billing' && !hasCreditCardPaymentMethod) || isAllotmentMappedLocationAddress}
                                        />
                                        <FormFeedback type='invalid'>{feedbackErrors.phone}</FormFeedback>
                                    </FormGroup>
                                </div>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' onClick={verifyAddress} className="flex-md-grow-1">
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Use this address", "lbl_check_usethisaddress")
                                :
                                "Use this address"
                            }
                        </Button>
                        <Button color='primary' outline={true} onClick={toggle} className="flex-md-grow-1">
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Cancel", "lbl_check_cancel")
                                :
                                "Cancel"
                            }
                        </Button>
                    </ModalFooter>
                </LoadingWrapper>
            </Modal>
        </>
    )
}
