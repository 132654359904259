import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import Config from '../../../helpers/Config';
import { useSelector } from 'react-redux';
import { Alert, Card, CardBody, CardHeader, CardSubtitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, FormFeedback, Label, Input, Spinner } from 'reactstrap'
import { showErrorMsg, validateEmail, validateZipCode } from '../../../helpers/ValidationHelper';
import { replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import Seo from '../../components/shared/Seo';
import { useLoginMutation } from '../../../services/auth';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { useAppDispatch } from '../../../stateManagment/reduxStore';
import rootAction from '../../../stateManagment/actions/rootAction';
import { useLazyGetSmartySuggestionsQuery } from "../../../services/site";
import debounce from 'lodash.debounce';

const UpdateEmployeeIdLoginInfo = (props) => {
    const { shippingAddress, phone, password, employeeId, firstName } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [LocalizationLabelsArray] = useState([]);
    const lastVisitedUrl = useSelector(state => state.commonReducer.lastVisitedUrl);
    const [login, { isFetching, isLoading }] = useLoginMutation();
    const { user, } = useSelector(state => state.userReducer);
    const [feedbackErrors, setFeedbackErrors] = useState({});
    const [feedback, setFeedback] = useState({
        message: '',
        type: '',
        open: false
    });
    const [customerInfo, setCustomerInfo] = useState({ address1: "", address2: "", city: "", stateCode: "", countryCode: "US", postalCode: "", phone: "", email: "", employeeId: "", allotmentAddressLabel: "", allotmentAddressId: 0 });
    const [openSuggestShippingDropdown, setOpenSuggestShippingDropdown] = useState(false);
    const [smartySuggestionShippingDropdown, setSmartySuggestionShippingDropdown] = useState([]);
    const [smartySuggest, { isFetching: isFetchingSuggestions }] = useLazyGetSmartySuggestionsQuery();
    const { smartyStreets: { acSettings: { c_enableSmartyStreetsAutoComplete: ssACEnabled }, avsSettings: { c_enableSmartyStreetsAddressValidation: ssAVSEnabled } }, isDedicatedAllotmentAddressesEnabled } = useSelector((state) => state.commonReducer);

    const toggleSuggestShippingDropdown = () => {
        setOpenSuggestShippingDropdown(ssAVSEnabled && !openSuggestShippingDropdown);
    };

    const handleCustomerInfoChange = (e) => {
        setCustomerInfo({
            ...customerInfo,
            [e.target.name]: e.target.value
        });
    };

    const suggestAddressDropdown = async (e) => {
        if (ssACEnabled && e.target.value.length > 2) {
            smartySuggest({ search: e.target.value }).unwrap().then((res) => {
                if (res.data?.response.length > 0) {
                    setSmartySuggestionShippingDropdown(res.data?.response);
                }
                setOpenSuggestShippingDropdown(res.data?.response.length > 0);
            });
        }
    };

    const debouncedSuggestAddressDropdown = useMemo(
        () => {
            return debounce(suggestAddressDropdown, 1000);
        }, []);

    const selectSmartyShippingAddress = (item) => {
        setCustomerInfo({
            ...customerInfo,
            address1: item.street_line,
            address2: '',
            city: item.city,
            stateCode: item.state,
            postalCode: item.zipcode
        });
        setSmartySuggestionShippingDropdown([]);
    };

    const validateFields = (key = null) => {
        const validateKeys = key ? [key] : ['address1', 'city', 'stateCode', 'countryCode', 'postalCode', 'phone', 'email', 'allotmentAddressLabel'];
        validateKeys.forEach(key => {
            customerInfo[key] = customerInfo[key]?.trim();
            if (key === 'phone') {
                if (!customerInfo[key] || customerInfo[key]?.length === 0) {
                    feedbackErrors[key] = 'Please enter a Phone Number.';
                } else if (customerInfo[key]?.length != 10 || customerInfo[key]?.match(/^\d+$/) === null) {
                    feedbackErrors[key] = 'Phone Number must be 10 characters long (numbers only).'
                }
            }
            else if (key === 'email') {
                if (!customerInfo[key] || customerInfo[key]?.length === 0) {
                    feedbackErrors[key] = 'Please enter your Email';
                } else if ((!validateEmail(customerInfo[key]))) {
                    feedbackErrors[key] = 'Please enter a valid Email address'
                }
            }
            else if (key === 'countryCode' || key === 'stateCode') {
                if (customerInfo[key]?.length === 0) {
                    feedbackErrors[key] = 'Please select an option.'
                }
            } else if (key === 'postalCode') {
                if (!validateZipCode(customerInfo[key])) {
                    feedbackErrors[key] = 'Please enter a valid zip code.';
                }
            } else if (key === 'allotmentAddressLabel' && isDedicatedAllotmentAddressesEnabled){
                if (customerInfo[key]?.length === 0) {
                    feedbackErrors[key] = 'Please fill out this field.'
                }
            } else {
                if (customerInfo[key]?.length === 0) {
                    feedbackErrors[key] = 'Please fill out this field.'
                }
            }
        });

        setFeedbackErrors({ ...feedbackErrors });
    };

    const removeFeedbackError = (e) => {
        delete feedbackErrors[e];
        setFeedbackErrors({ ...feedbackErrors });
    };

    const submitForm = async (event) => {
        event.preventDefault();
        // Clean errors before validating
        setFeedback({
            message: '',
            type: '',
            open: false
        });
        var validateKeys = ['address1', 'city', 'stateCode', 'countryCode', 'postalCode', 'phone', 'email'];
        validateKeys.forEach(key => {
            removeFeedbackError(key);
        });
        // Validate fields
        validateFields();
        if (Object.keys(feedbackErrors).length > 0) {
            // Invalid fields
            var element = document.querySelector('.is-invalid');
            if (element) {
                window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
            }
        } else {
            // Valid fields
            try {
                dispatch(rootAction.commonAction.setLoading(true));
                const headers = {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                };
                var params = {
                    requestParameters: {
                        address1: customerInfo.address1,
                        address2: customerInfo.address2,
                        city: customerInfo.city,
                        stateCode: customerInfo.stateCode,
                        countryCode: customerInfo.countryCode,
                        postalCode: customerInfo.postalCode,
                        phone: customerInfo.phone,
                        email: customerInfo.email,
                        employeeId: customerInfo.employeeId,
                        password: password,
                        allotmentAddressLabel: customerInfo.allotmentAddressLabel,
                        allotmentAddressId: customerInfo.allotmentAddressId
                    }
                };
                var response = await MakeApiCallAsync(Config.END_POINT_NAMES['UPDATE_EMPLOYEE_ID_LOGIN_INFO'], null, params, headers, "POST", true);
                dispatch(rootAction.commonAction.setLoading(false));
                let parsed = JSON.parse(response.data.data);
                if (parsed.Success) {
                    submitLogin();
                } else {
                    if (parsed.EmailAlreadyInUse) {
                        setFeedback({
                            type: 'danger',
                            message: 'A consumer with email ' + customerInfo.email + ' already exists',
                            open: true
                        });
                    } else {
                        console.log(parsed.Message);
                        setFeedback({
                            type: 'danger',
                            message: 'Error updating profile. Please, try again.',
                            open: true
                        });
                    }
                    window.scrollTo({top: 0, behavior: 'smooth'});
                }
            } catch (err) {
                console.log(err);
                showErrorMsg("An error occured. Please try again!");
            }
        }
    };

    const submitLogin = () => {
        login({ Email: employeeId, Password: password });
    };

    useEffect(() => {
        setCustomerInfo({
            ...shippingAddress,
            phone: phone,
            email: '',
            employeeId: employeeId
        });
    }, []);

    useEffect(() => {
        if (user?.UserID && user?.JWTToken) {
            navigate(lastVisitedUrl || '/');
        }
    }, [user]);

    return (
        <>
            <LoadingScreen loading={isFetching || isLoading} />
            <Seo title="Login" description="Login" keywords="Login" />
            <section className="edit-content-page pb-8 pb-md-9" id="update-email-address">
                <div className="container-fluid">
                    <div className="section-title d-flex align-items-center justify-content-between justify-content-md-center position-relative pt-md-8 pb-3">
                        <h1 className="h2 mb-0">
                            {LocalizationLabelsArray.length > 0 ?
                                replaceLoclizationLabel(LocalizationLabelsArray, "Returning Customers", "lbl_updateemail_title")
                                :
                                "Welcome Back"
                            }
                            &nbsp;{firstName}!
                        </h1>
                    </div>

                    <Alert color={feedback.type} isOpen={feedback.open}>
                        {feedback.message}
                    </Alert>

                    <div className="row">
                        <div className="col-12">
                            <Form onSubmit={submitForm} noValidate>
                                <Card color='light' className='card-my-account border-0'>
                                    <CardHeader tag='p' className='no-bg-header border-bottom-0 pt-md-6'>
                                        <CardSubtitle tag="p">
                                            {LocalizationLabelsArray.length > 0 ?
                                                replaceLoclizationLabel(LocalizationLabelsArray, "Before you can begin shopping we need you to verify and update your account information. The information below will allow you to log into your account and place orders.", "lbl_updateemail_welcomesubtitle")
                                                :
                                                "Before you can begin shopping we need you to verify and update your account information. The information below will allow you to log into your account and place orders."
                                            }
                                        </CardSubtitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormGroup className='required'>
                                            <Label>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Phone Number", "lbl_check_phonenumber")
                                                    :
                                                    "Phone Number"
                                                }
                                            </Label>
                                            <Input type='tel' name='phone' id='phone' maxLength='10'
                                                value={customerInfo.phone}
                                                onChange={(e) => handleCustomerInfoChange(e)}
                                                onFocus={(e) => removeFeedbackError(e.target.name)}
                                                onBlur={(e) => validateFields(e.target.name)}
                                                invalid={!!feedbackErrors.phone}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.phone}</FormFeedback>
                                        </FormGroup>
                                        <FormGroup className='required'>
                                            <Label>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Email Address", "lbl_check_emailaddress")
                                                    :
                                                    "Email Address"
                                                }
                                            </Label>
                                            <Input type='email' name='email' id='email'
                                                value={customerInfo.email}
                                                onChange={(e) => { handleCustomerInfoChange(e) }}
                                                onFocus={(e) => removeFeedbackError(e.target.name)}
                                                onBlur={(e) => validateFields(e.target.name)}
                                                invalid={!!feedbackErrors.email}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.email}</FormFeedback>


                                        </FormGroup>
                                    </CardBody>
                                    <hr className='my-0'></hr>

                                    {/* Shipping Address */}
                                    <CardHeader tag='h5' className='no-bg-header border-bottom-0 pt-md-6'>
                                        {LocalizationLabelsArray.length > 0 ?
                                            replaceLoclizationLabel(LocalizationLabelsArray, "Shipping Address", "lbl_updateemail_shippingaddress")
                                            :
                                            "Shipping Address"
                                        }
                                    </CardHeader>
                                    <CardBody>
                                        { isDedicatedAllotmentAddressesEnabled ? 
                                            <>
                                                <FormGroup className='required'>
                                                <Label for='ShippingAllotmentAddressLabel'>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "First Name", "lbl_check_addresslabel")
                                                        :
                                                        "Address Label"
                                                    }
                                                </Label>
                                                <Input type='text' name='allotmentAddressLabel' id='ShippingAllotmentAddressLabel' maxLength='50'
                                                    value={customerInfo.allotmentAddressLabel}
                                                    onChange={(e) => handleCustomerInfoChange(e)}
                                                    onFocus={(e) => removeFeedbackError(e.target.name)}
                                                    onBlur={(e) => validateFields(e.target.name)}
                                                    invalid={!!feedbackErrors.allotmentAddressLabel}
                                                >
                                                </Input>
                                                <FormFeedback type='invalid'>{feedbackErrors.allotmentAddressLabel}</FormFeedback>
                                            </FormGroup>
                                            </>
                                            :
                                            <></>
                                        }

                                        <FormGroup className='required'>
                                            <Label for='ShippingAddress1'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Address 1", "lbl_check_address1")
                                                    :
                                                    "Address 1"
                                                }
                                            </Label>
                                            <Dropdown isOpen={openSuggestShippingDropdown} toggle={toggleSuggestShippingDropdown}>
                                                <DropdownToggle tag="div">
                                                    <Input type='text' name='address1' id='ShippingAddress1' maxLength='35'
                                                        value={customerInfo.address1 || ''}
                                                        onChange={(e) => { handleCustomerInfoChange(e); debouncedSuggestAddressDropdown(e); }}
                                                        invalid={!!feedbackErrors.address1}
                                                        autoComplete={ssACEnabled ? "ShippingAddress1" : ""}
                                                    />
                                                    <FormFeedback type='invalid'>{feedbackErrors.address1}</FormFeedback>
                                                </DropdownToggle>
                                                {smartySuggestionShippingDropdown.length > 0 && <DropdownMenu>
                                                    {smartySuggestionShippingDropdown?.map((item) => {
                                                        return (
                                                            <DropdownItem onClick={() => selectSmartyShippingAddress(item)}>{item.asString}</DropdownItem>
                                                        )
                                                    })}
                                                </DropdownMenu>}
                                                {isFetchingSuggestions && <Spinner className="position-absolute" style={{ right: 10, top: 10, left: 'auto' }} />}
                                            </Dropdown>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for='ShippingAddress2'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Address 2 (optional)", "lbl_check_address2optional")
                                                    :
                                                    "Address 2 (optional)"
                                                }
                                            </Label>
                                            <Input type='text' name='address2' id='ShippingAddress2' maxLength='35'
                                                value={customerInfo.address2}
                                                onChange={(e) => handleCustomerInfoChange(e)}
                                                autoComplete={ssACEnabled ? "ShippingAddress2" : ""}
                                            >
                                            </Input>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='ShippingCountry'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Country", "lbl_check_country")
                                                    :
                                                    "Country"
                                                }
                                            </Label>
                                            <Input name='countryCode' id='ShippingCountry' type='select' className='custom-select'
                                                value={customerInfo.countryCode ? customerInfo.countryCode : 'US'}
                                                onChange={(e) => handleCustomerInfoChange(e)}
                                                onFocus={(e) => removeFeedbackError(e.target.name)}
                                                onBlur={(e) => validateFields(e.target.name)}
                                                invalid={!!feedbackErrors.countryCode}>
                                                <option id="US" value="US">United States</option>
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.countryCode}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='ShippingState'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "State", "lbl_check_state")
                                                    :
                                                    "State"
                                                }
                                            </Label>
                                            <Input name='stateCode' id='ShippingState' type='select' className='custom-select'
                                                value={customerInfo.stateCode}
                                                onChange={(e) => handleCustomerInfoChange(e)}
                                                onFocus={(e) => removeFeedbackError(e.target.name)}
                                                onBlur={(e) => validateFields(e.target.name)}
                                                invalid={!!feedbackErrors.stateCode}>
                                                <option id="" value="">Select State</option>
                                                <option id="AL" value="AL">Alabama</option>
                                                <option id="AK" value="AK">Alaska</option>
                                                <option id="AS" value="AS">American Samoa</option>
                                                <option id="AZ" value="AZ">Arizona</option>
                                                <option id="AR" value="AR">Arkansas</option>
                                                <option id="CA" value="CA">California</option>
                                                <option id="CO" value="CO">Colorado</option>
                                                <option id="CT" value="CT">Connecticut</option>
                                                <option id="DE" value="DE">Delaware</option>
                                                <option id="DC" value="DC">District of Columbia</option>
                                                <option id="FL" value="FL">Florida</option>
                                                <option id="GA" value="GA">Georgia</option>
                                                <option id="GU" value="GU">Guam</option>
                                                <option id="HI" value="HI">Hawaii</option>
                                                <option id="ID" value="ID">Idaho</option>
                                                <option id="IL" value="IL">Illinois</option>
                                                <option id="IN" value="IN">Indiana</option>
                                                <option id="IA" value="IA">Iowa</option>
                                                <option id="KS" value="KS">Kansas</option>
                                                <option id="KY" value="KY">Kentucky</option>
                                                <option id="LA" value="LA">Louisiana</option>
                                                <option id="ME" value="ME">Maine</option>
                                                <option id="MD" value="MD">Maryland</option>
                                                <option id="MA" value="MA">Massachusetts</option>
                                                <option id="MI" value="MI">Michigan</option>
                                                <option id="MN" value="MN">Minnesota</option>
                                                <option id="MS" value="MS">Mississippi</option>
                                                <option id="MO" value="MO">Missouri</option>
                                                <option id="MT" value="MT">Montana</option>
                                                <option id="NE" value="NE">Nebraska</option>
                                                <option id="NV" value="NV">Nevada</option>
                                                <option id="NH" value="NH">New Hampshire</option>
                                                <option id="NJ" value="NJ">New Jersey</option>
                                                <option id="NM" value="NM">New Mexico</option>
                                                <option id="NY" value="NY">New York</option>
                                                <option id="NC" value="NC">North Carolina</option>
                                                <option id="ND" value="ND">North Dakota</option>
                                                <option id="MP" value="MP">North Mariana Islands</option>
                                                <option id="OH" value="OH">Ohio</option>
                                                <option id="OK" value="OK">Oklahoma</option>
                                                <option id="OR" value="OR">Oregon</option>
                                                <option id="PA" value="PA">Pennsylvania</option>
                                                <option id="PR" value="PR">Puerto Rico</option>
                                                <option id="RI" value="RI">Rhode Island</option>
                                                <option id="SC" value="SC">South Carolina</option>
                                                <option id="SD" value="SD">South Dakota</option>
                                                <option id="TN" value="TN">Tennessee</option>
                                                <option id="TX" value="TX">Texas</option>
                                                <option id="UT" value="UT">Utah</option>
                                                <option id="VT" value="VT">Vermont</option>
                                                <option id="VI" value="VI">Virgin Islands</option>
                                                <option id="VA" value="VA">Virginia</option>
                                                <option id="WA" value="WA">Washington</option>
                                                <option id="WV" value="WV">West Virginia</option>
                                                <option id="WI" value="WI">Wisconsin</option>
                                                <option id="WY" value="WY">Wyoming</option>
                                                <option id="AA" value="AA">Armed Forces AA</option>
                                                <option id="AE" value="AE">Armed Forces AE</option>
                                                <option id="AP" value="AP">Armed Forces AP</option>
                                                <option className="d-none" id="OTHER" value="OTHER">OTHER</option>
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.stateCode}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='ShippingCity'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "City", "lbl_check_city")
                                                    :
                                                    "City"
                                                }
                                            </Label>
                                            <Input type='text' name='city' id='ShippingCity'
                                                maxLength='30'
                                                value={customerInfo.city}
                                                onChange={(e) => handleCustomerInfoChange(e)}
                                                onFocus={(e) => removeFeedbackError(e.target.name)}
                                                onBlur={(e) => validateFields(e.target.name)}
                                                invalid={!!feedbackErrors.city}
                                                autoComplete={ssACEnabled ? "ShippingCity" : ""}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.city}</FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='required'>
                                            <Label for='ShippingZip'>
                                                {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Zip", "lbl_check_zip")
                                                    :
                                                    "Zip"
                                                }
                                            </Label>
                                            <Input type='text' name='postalCode' id='ShippingZip' minLength='5' maxLength='10'
                                                value={customerInfo.postalCode}
                                                onChange={(e) => handleCustomerInfoChange(e)}
                                                onFocus={(e) => removeFeedbackError(e.target.name)}
                                                onBlur={(e) => validateFields(e.target.name)}
                                                invalid={!!feedbackErrors.postalCode}
                                                autoComplete={ssACEnabled ? "ShippingZip" : ""}
                                            >
                                            </Input>
                                            <FormFeedback type='invalid'>{feedbackErrors.postalCode}</FormFeedback>
                                        </FormGroup>

                                        <div className='row'>
                                            <div className="col-12 col-md mb-4 mb-md-0">
                                                <button type="submit" name="save" className="btn btn-save btn-block btn-primary">
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "Save", "lbl_updateemail_save")
                                                        :
                                                        "Update & Login"
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default UpdateEmployeeIdLoginInfo;
