import { createSlice } from '@reduxjs/toolkit';
import { authApi } from '../../services/auth.ts';
import { userApi } from '../../services/user.ts';
import { setAvailablePaymentMethods } from './checkoutSlice.ts';

const initialState = {
    user: {},
    token: '',
    cartId: '',
    ecomCustNo: '',
    defaultSc: '',
    hqAccount: '',
    bECustNo: '',
    billingAddress: {},
    shippingAddress: {},
    companyNumber: 0,
    flashMessage: '',
    rememberMe: false
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setSizingTemplate: (state, action) => {
            state.user.SelectedAllotmentPackageID = action.payload.packageId;
            state.user.SizingTemplate = action.payload.sizingTemplate;
        },
        setUser: (state, action) => {
            const userData = action.payload;
            state.user = userData;
            state.token = userData.JWTToken;
            state.cartId = userData.CartID;
            state.ecomCustNo = userData.EcomCustNo;
            state.defaultSc = userData.CustDefaultSc;
            state.hqAccount = userData.CustHqAccount;
            state.bECustNo = userData.BECustNo;
            state.companyNumber = userData.CompanyNumber;

            // backwards compatibility
            localStorage.setItem('CartID', userData.CartID);
            localStorage.setItem('CompanyNumber', userData.CompanyNumber.toString());
            localStorage.setItem('BECustNo', userData.BECustNo);
            localStorage.setItem('EcomCustNo', userData.EcomCustNo);
            localStorage.setItem('Token', userData.JWTToken);
            localStorage.setItem('user', JSON.stringify(userData));
            localStorage.setItem('navSoleLink', '');
        },
        setUserAddress: (state, action) => {
            if (!!action.payload.shipping)
                state.shippingAddress = action.payload.shipping;
            if (!!action.payload.billing)
                state.billingAddress = action.payload.billing;
        },
        setUserToken: (state, action) => {
            localStorage.setItem('Token', action.payload);
            state.user.JWTToken = action.payload;
        },
        logOutUser: (state, action) => {
            localStorage.removeItem('CartID');
            localStorage.removeItem('CompanyNumber');
            localStorage.removeItem('BECustNo');
            localStorage.removeItem('EcomCustNo');
            localStorage.removeItem('Token');
            localStorage.removeItem('user');
            localStorage.removeItem('navSoleLink');
            let flashMessage = action.payload?.flashMessage ?? ''
            if (state.rememberMe) {
                return {
                    ...initialState,
                    rememberMe: true,
                    flashMessage,
                    user: {
                        EmailAddress: state.user.EmailAddress,
                    }
                };
            }
            return {
                ...initialState,
                flashMessage
            }
        },
        rotateCart: (state, action) => {
            localStorage.setItem('CartID', action.payload);
            return {
                ...state,
                cartId: action.payload,
                user: {
                    ...state.user,
                    CartID: action.payload
                }
            }
        },
        updateCreditOptimiscally: (state, action) => {
            state.user.CreditBalance = action.payload;
        },
        updatePayrollDeductionOptimiscally: (state, action) => {
            // this will force current session to disable PD if order was just placed with PD
            state.user.PDOrderInRecentFortnight = true;
            state.user.PayrollDeduction.AvailablePDBalance = action.payload;
        },
        updateAllotmentsOptimiscally: (state, action) => {
            state.user.SizingTemplate = action.payload;
        },
        rememberMe: (state, action) => {
            state.rememberMe = action.payload;
        },
        resetFlashMessage: (state) => {
            state.flashMessage = '';
        },
        isOnAccountCapable: (state, action) => {
            const availablePaymentMethods = setAvailablePaymentMethods(action.payload.paymentMethods, 99, 0, 0, 0, false, false, false, action.payload.isOnAccount, action.payload.isToggled);
            state.user.IsOnAccountCapable = availablePaymentMethods?.find(pm => pm.id === 'ON_ACCOUNT') || action.payload.isToggled ? true : false;
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addMatcher(authApi.endpoints.login.matchPending, () => {

            })
            .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
                state.user = {
                    ...action.payload,
                    PayrollDeduction: action.payload.PayrollDeduction && action.payload.PayrollDeductionEnabled ? {
                        ...action.payload.PayrollDeduction,
                        AvailablePDBalance: action.payload.PayrollDeduction?.AvailablePDBalance || 0,
                    } : {
                        AvailablePDBalance: 0
                    },
                    PDOrderInRecentFortnight: action.payload.PDOrderInRecentFortnight,
                    CreditBalance: action.payload.CreditEnabled ? action.payload.CreditBalance || 0 : 0,
                    SizingTemplate: action.payload.SizingTemplate || {}
                };
            })
            .addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (state, action) => {
                const token = action.payload.JWTToken;
                state.user = {
                    ...state.user,
                    JWTToken: token,
                    TokenValidTo: action.payload.TokenValidTo
                }
                state.token = token;
                localStorage.setItem('Token', token);
            })
            .addMatcher(userApi.endpoints.me.matchFulfilled, (state, action) => {
                state.user = {
                    ...state.user,
                    ...action.payload,
                    PayrollDeduction: action.payload.PayrollDeduction && action.payload.PayrollDeductionEnabled ? {
                        ...action.payload.PayrollDeduction,
                        AvailablePDBalance: action.payload.PayrollDeduction?.AvailablePDBalance || 0,
                    } : {
                        AvailablePDBalance: 0
                    },
                    CreditBalance: action.payload.CreditEnabled ? action.payload.CreditBalance || 0 : 0,
                    // SizingTemplate: action.payload.SizingTemplate || {}
                };
            })
            .addMatcher(authApi.endpoints.login.matchRejected, () => {
                console.log('rejected')
            })
    }
})

export const { setUser, logOutUser } = userSlice.actions;
export const userActions = userSlice.actions;
export default userSlice.reducer;
